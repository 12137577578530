import type {Dispatch} from 'redux';
import {browserHistory as router} from 'react-router';

import type {
	CreateLootOptionInput,
	CreateLootOptionResult,
	UpdateLootOptionResult,
	UpdateLootOptionInput,
	UpdateNoteInput,
	UpdateNoteResult
} from 'api-types';

import * as rpc from '../../../shared/constants/rpc';
import type {Difficulties} from '@constants/wow';

import * as toolbox from '../../helpers/toolbox';
import api from '../../helpers/api';

import type {RootAction} from '..';
import * as BannerDuck from '../banner';
import * as GuildDuck from '../guild';
import * as LootDuck from '.';

import {LootSelection, ILootSelectionClass} from '../../models/loot-selection';
import {LootCoin, ILootCoinClass} from '../../models/loot-coin';
import {LootOption} from '../../models/loot-option';
import {Guild} from '../../models/guild';

interface ICreateOption {
	isVisibleInRoster: boolean;
	name: string;
}

export function createOption(data: ICreateOption): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds);
		if (!guildId) return Promise.resolve();

		const action: LootDuck.IActions['CREATE'] = {type: LootDuck.CREATE};
		dispatch(action);

		return api
			.call<CreateLootOptionInput, CreateLootOptionResult>(rpc.LOOT_OPTION_CREATE, {
				isVisibleInRoster: data.isVisibleInRoster,
				name: data.name,
				guildId
			})
			.then(
				(message) => {
					const successAction: LootDuck.IActions['CREATE_SUCCESS'] = {
						type: LootDuck.CREATE_SUCCESS
					};

					dispatch(successAction);
					dispatch(BannerDuck.addSuccessBanner('Created loot option'));

					const baseUrl = Guild.createBaseUrl(guildId);
					router.push(
						`${baseUrl}/guild-settings/loot-options/${message.data.lootOption.id}`
					);
				},

				(message) => {
					const failureAction: LootDuck.IActions['CREATE_FAILURE'] = {
						type: LootDuck.CREATE_FAILURE
					};

					dispatch(failureAction);
					dispatch(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}

interface IUpdateOption {
	id: number;
	isVisibleInRoster: boolean;
	name: string;
}

export function updateOption(data: IUpdateOption): Thunk<void> {
	return (dispatch) => {
		const action: LootDuck.IActions['UPDATE'] = {type: LootDuck.UPDATE};
		dispatch(action);

		return api
			.call<UpdateLootOptionInput, UpdateLootOptionResult>(rpc.LOOT_OPTION_UPDATE, {
				name: data.name,
				isVisibleInRoster: data.isVisibleInRoster,
				id: data.id
			})
			.then(
				() => {
					const successAction: LootDuck.IActions['UPDATE_SUCCESS'] = {
						type: LootDuck.UPDATE_SUCCESS
					};

					dispatch(successAction);
					dispatch(BannerDuck.addSuccessBanner('Updated loot option'));
				},

				(message) => {
					const failureAction: LootDuck.IActions['UPDATE_FAILURE'] = {
						type: LootDuck.UPDATE_FAILURE
					};

					dispatch(failureAction);
					dispatch(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}

export function deleteOption(id: number): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds);

		const action: LootDuck.IActions['DELETE'] = {type: LootDuck.DELETE};
		dispatch(action);

		return api.call(rpc.LOOT_OPTION_DELETE, {id}).then(
			() => {
				const successAction: LootDuck.IActions['DELETE_SUCCESS'] = {
					type: LootDuck.DELETE_SUCCESS
				};

				dispatch(successAction);

				const baseUrl = Guild.createBaseUrl(guildId!);
				router.push(`${baseUrl}/guild-settings/loot-options/new`);
			},

			(message) => {
				const failureAction: LootDuck.IActions['DELETE_FAILURE'] = {
					type: LootDuck.DELETE_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

export function reorderOption(id: number, order: number): Thunk<void> {
	return (dispatch, getState) => {
		const option = LootDuck.getOption(getState().loot, id);
		if (!option) return Promise.reject();

		const optimisticId = toolbox.optimistic.getId();
		const action: LootDuck.IActions['REORDER'] = {
			...toolbox.optimistic.begin(optimisticId),
			type: LootDuck.REORDER,
			payload: {
				option: new LootOption({...option, order})
			}
		};

		dispatch(action);

		return api.call(rpc.LOOT_OPTION_REORDER, {id, order}).then(
			() => {
				const successAction: LootDuck.IActions['REORDER_SUCCESS'] = {
					...toolbox.optimistic.commit(optimisticId),
					type: LootDuck.REORDER_SUCCESS
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: LootDuck.IActions['REORDER_FAILURE'] = {
					...toolbox.optimistic.revert(optimisticId),
					type: LootDuck.REORDER_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

export function bumpUpdated(characterId: number): Thunk<void> {
	return (dispatch) => {
		dispatch<LootDuck.IActions['BUMP']>({
			type: LootDuck.BUMP
		});

		const payload = {
			characterId
		};

		return api.call(rpc.LOOT_BUMP, payload).then(
			() => {
				dispatch<LootDuck.IActions['BUMP_SUCCESS']>({
					type: LootDuck.BUMP_SUCCESS
				});

				dispatch(BannerDuck.addSuccessBanner('Loot marked as current'));
			},

			(message) => {
				dispatch<LootDuck.IActions['BUMP_FAILURE']>({
					type: LootDuck.BUMP_FAILURE
				});

				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

export interface IRclcSelection {
	characterId: number;

	difficulty: Difficulties;
	wowItemId: string;

	optionId: number | undefined;
}

export function submitRclc(
	guildId: number,
	rclcSelections: IRclcSelection[]
): Thunk<void> {
	return (dispatch) => {
		dispatch<LootDuck.IActions['RCLC_SUBMIT']>({
			type: LootDuck.RCLC_SUBMIT
		});

		const payload = {
			guildId,
			rclcSelections
		};

		return api.call(rpc.LOOT_RCLC, payload).then(
			() => {
				dispatch<LootDuck.IActions['RCLC_SUBMIT_SUCCESS']>({
					type: LootDuck.RCLC_SUBMIT_SUCCESS
				});

				dispatch(BannerDuck.addSuccessBanner('Loot sheet has been updated'));
			},

			(message) => {
				dispatch<LootDuck.IActions['RCLC_SUBMIT_FAILURE']>({
					type: LootDuck.RCLC_SUBMIT_FAILURE
				});

				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

interface IMakeSelection {
	characterId: number;
	difficulty: string;
	wowItemId: WowItemId;

	optionId?: number;
}

export function dispatchOptimisticLootSelection(
	dispatch: Dispatch<RootAction>,
	state: IRootState,
	data: IMakeSelection
) {
	const selections = LootDuck.getSelectionsForCharacter(state.loot, data.characterId);
	const selection = selections.find(
		(s) => s.wowItemId === data.wowItemId && s.difficulty === data.difficulty
	);

	const optimisticId = toolbox.optimistic.getId();
	if (data.optionId) {
		const newData: ILootSelectionClass = selection
			? {...selection, optionId: data.optionId}
			: {
					characterId: data.characterId,
					difficulty: data.difficulty,
					wowItemId: data.wowItemId,
					optionId: data.optionId,
					note: null
			  };

		const action: LootDuck.IActions['SELECTION'] = {
			...toolbox.optimistic.begin(optimisticId),
			type: LootDuck.SELECTION,
			payload: {
				selection: new LootSelection(newData)
			}
		};

		dispatch(action);
	} else if (selection) {
		const action: LootDuck.IActions['SELECTION_REMOVE'] = {
			...toolbox.optimistic.begin(optimisticId),
			type: LootDuck.SELECTION_REMOVE,
			payload: {selection}
		};

		dispatch(action);
	}

	return optimisticId;
}

export function makeSelection(data: IMakeSelection): Thunk<void> {
	return (dispatch, getState) => {
		const optimisticId = dispatchOptimisticLootSelection(dispatch, getState(), data);

		const payload = {
			characterId: data.characterId,
			difficulty: data.difficulty,
			wowItemId: data.wowItemId,

			optionId: data.optionId
		};

		return api.call(rpc.LOOT_SELECTION, payload).then(
			() => {
				const successAction: LootDuck.IActions['SELECTION_SUCCESS'] = {
					...toolbox.optimistic.commit(optimisticId),
					type: LootDuck.SELECTION_SUCCESS
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: LootDuck.IActions['SELECTION_FAILURE'] = {
					...toolbox.optimistic.revert(optimisticId),
					type: LootDuck.SELECTION_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

interface IUpdateNote {
	characterId: number;
	difficulty: Difficulties;
	wowItemId: string;

	note: string | null;
}

/** Optimistically update a LootSelection's note */
export function dispatchOptimisticLootNote(
	dispatch: Dispatch<RootAction>,
	state: IRootState,
	data: IUpdateNote
): OptimisticId | null {
	// find the existing selection
	const selections = LootDuck.getSelectionsForCharacter(state.loot, data.characterId);
	const selection = selections.find(
		(s) => s.wowItemId === data.wowItemId && s.difficulty === data.difficulty
	);

	if (!selection) return null;

	const optimisticId = toolbox.optimistic.getId();

	dispatch<LootDuck.IActions['SELECTION_NOTE']>({
		...toolbox.optimistic.begin(optimisticId),
		type: LootDuck.SELECTION_NOTE,
		payload: {
			selection: {
				...selection,
				note: data.note
			}
		}
	});

	return optimisticId;
}

/** Update a LootSelection's note */
export function updateNote(data: IUpdateNote): Thunk<void> {
	return (dispatch, getState) => {
		const optimisticId = dispatchOptimisticLootNote(dispatch, getState(), data);
		if (!optimisticId) return Promise.resolve();

		return api
			.call<UpdateNoteInput, UpdateNoteResult>(rpc.LOOT_NOTE, {
				characterId: data.characterId,
				wowItemId: data.wowItemId,
				difficulty: data.difficulty,
				note: data.note
			})
			.then(
				() => {
					dispatch<LootDuck.IActions['SELECTION_NOTE_SUCCESS']>({
						...toolbox.optimistic.commit(optimisticId),
						type: LootDuck.SELECTION_NOTE_SUCCESS
					});
				},

				(message) => {
					dispatch<LootDuck.IActions['SELECTION_NOTE_FAILURE']>({
						...toolbox.optimistic.revert(optimisticId),
						type: LootDuck.SELECTION_NOTE_FAILURE
					});

					dispatch(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}

interface IMakeCoin {
	characterId: number;
	difficulty: string;
	wowBossId: WowBossId;

	isCoining: boolean;
}

export function dispatchOptimisticLootCoin(
	dispatch: Dispatch<RootAction>,
	state: IRootState,
	data: IMakeCoin
) {
	const coins = LootDuck.getCoinsForCharacter(state.loot, data.characterId);
	const coin = coins.find(
		(c) => c.wowBossId === data.wowBossId && c.difficulty === data.difficulty
	);

	const optimisticId = toolbox.optimistic.getId();
	if (data.isCoining) {
		const newData: ILootCoinClass = coin || {
			characterId: data.characterId,
			difficulty: data.difficulty,
			wowBossId: data.wowBossId
		};

		const action: LootDuck.IActions['COIN'] = {
			...toolbox.optimistic.begin(optimisticId),
			type: LootDuck.COIN,
			payload: {
				coin: new LootCoin(newData)
			}
		};

		dispatch(action);
	} else if (coin) {
		const action: LootDuck.IActions['COIN_REMOVE'] = {
			...toolbox.optimistic.begin(optimisticId),
			type: LootDuck.COIN_REMOVE,
			payload: {coin}
		};

		dispatch(action);
	}

	return optimisticId;
}

export function makeCoin(data: IMakeCoin): Thunk<void> {
	return (dispatch, getState) => {
		const optimisticId = dispatchOptimisticLootCoin(dispatch, getState(), data);

		const payload = {
			characterId: data.characterId,
			difficulty: data.difficulty,
			wowBossId: data.wowBossId,

			isCoining: data.isCoining
		};

		return api.call(rpc.LOOT_COIN, payload).then(
			() => {
				const successAction: LootDuck.IActions['COIN_SUCCESS'] = {
					...toolbox.optimistic.commit(optimisticId),
					type: LootDuck.COIN_SUCCESS
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: LootDuck.IActions['COIN_FAILURE'] = {
					...toolbox.optimistic.revert(optimisticId),
					type: LootDuck.COIN_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}
