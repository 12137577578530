import React from 'react';
import {Link} from 'react-router';

import * as wow from '../../../constants/wow';

import type {InstanceFilterOption} from '../Loot';
import {FilterSelect, ISelectOption as IFilterOption} from '../../Utils/FilterSelect';
import SelectInput, {ISelectOption} from '../../Utils/SelectInput';

const difficultyOptions = Object.values(wow.Difficulties)
	.reverse()
	.map((difficulty) => ({
		name: wow.DifficultyDisplay[difficulty],
		id: difficulty
	}));

interface IProps {
	onLootOptionSelect(lootOptionId: LootOptionId): void;
	onCharacterSelect(characterId: CharacterId | null): void;
	onDifficultySelect(difficulty: string): void;
	onInstanceSelect(wowInstanceId: InstanceFilterOption): void;
	onRosterSelect(rosterId: RosterId): void;

	canChangeRoster: boolean;
	isShowingRclcButton: boolean;

	characterSelectOptions: IFilterOption[];
	rosterSelectOptions: ISelectOption[];
	instanceSelectOptions: ISelectOption[];
	lootOptionSelectOptions: ISelectOption[];

	selectedLootOptionId: LootOptionId | undefined;
	difficulty: string;
	wowInstanceId: InstanceFilterOption;
	characterId: CharacterId | undefined;
	rosterId: RosterId | undefined;
	rclcUrl: string;
}

export const FilterControls: React.FC<IProps> = (props) => {
	return (
		<div className="filter-controls">
			<FilterSelect
				onChange={(opt: {value: number} | null) => {
					props.onCharacterSelect(opt ? opt.value : null);
				}}
				placeholder="View a character"
				className="character-select"
				options={props.characterSelectOptions}
				value={props.characterId}
			/>

			{props.canChangeRoster && (
				<SelectInput
					onChange={props.onRosterSelect}
					items={props.rosterSelectOptions}
					selectedId={props.rosterId}
					placeholder="Filter to roster"
					size="big"
				/>
			)}

			<SelectInput
				onChange={props.onInstanceSelect}
				items={props.instanceSelectOptions}
				selectedId={props.wowInstanceId}
				className="instance-select"
				size="big"
			/>

			<SelectInput
				onChange={props.onDifficultySelect}
				items={difficultyOptions}
				selectedId={props.difficulty}
				className="difficulty-select"
				size="big"
			/>

			<SelectInput
				onChange={props.onLootOptionSelect}
				items={props.lootOptionSelectOptions}
				selectedId={props.selectedLootOptionId}
				className="loot-option-select"
				placeholder="Loot filter"
				size="big"
			/>

			{props.isShowingRclcButton && (
				<Link className="rclc-button" to={props.rclcUrl}>
					RCLootCouncil
				</Link>
			)}
		</div>
	);
};
