import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';

// `fromNow` requires the relative plugin
dayjs.extend(relativeTime);

// `calendar` requires the calendar plugin
dayjs.extend(calendar);

export const dates = dayjs;
