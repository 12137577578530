import * as feed from '../../constants/feed';

import * as GuildDuck from '../guild';
import * as DemoDuck from '../demo';
import * as LootDuck from '.';
import type {RootAction} from '..';

import {LootSelection, ILootSelectionClass} from '../../models/loot-selection';
import {LootCoin, ILootCoinClass} from '../../models/loot-coin';
import {LootOption} from '../../models/loot-option';

const initialState: LootDuck.IState = {
	selectionsByCharacterId: {},
	coinsByCharacterId: {},
	optionsById: {},

	isSubmittingRclc: false,
	isSubmitting: false,
	isDeleting: false,
	isBumping: false
};

function insertOrUpdateSelection(state: LootDuck.IState, newSel: LootSelection) {
	let selections: LootSelection[] =
		state.selectionsByCharacterId[newSel.characterId] || [];

	// filter out the selection
	selections = selections.filter(
		(s) => s.wowItemId !== newSel.wowItemId || s.difficulty !== newSel.difficulty
	);

	return {
		...state.selectionsByCharacterId,

		// add it into the list and re-set the list
		[newSel.characterId]: [...selections, newSel]
	};
}

function deleteSelection(state: LootDuck.IState, oldSel: ILootSelectionClass) {
	let selections: LootSelection[] = state.selectionsByCharacterId[oldSel.characterId];

	// ezpz exit if there's no selections for whatever reason
	if (!selections || !selections.length) return state.selectionsByCharacterId;

	// filter out the selection
	selections = selections.filter(
		(s) => s.wowItemId !== oldSel.wowItemId || s.difficulty !== oldSel.difficulty
	);

	return {
		...state.selectionsByCharacterId,
		[oldSel.characterId]: selections
	};
}

function insertOrUpdateCoin(state: LootDuck.IState, newCoin: LootCoin) {
	let coins: LootCoin[] = state.coinsByCharacterId[newCoin.characterId] || [];

	// filter out the coin
	coins = coins.filter(
		(c) => c.wowBossId !== newCoin.wowBossId || c.difficulty !== newCoin.difficulty
	);

	return {
		...state.coinsByCharacterId,

		// add it into the list and re-set the list
		[newCoin.characterId]: [...coins, newCoin]
	};
}

function deleteCoin(state: LootDuck.IState, oldCoin: ILootCoinClass) {
	let coins: LootCoin[] = state.coinsByCharacterId[oldCoin.characterId];

	// ezpz exit if there's no coins for whatever reason
	if (!coins || !coins.length) return state.coinsByCharacterId;

	// filter out the coin
	coins = coins.filter(
		(c) => c.wowBossId !== oldCoin.wowBossId || c.difficulty !== oldCoin.difficulty
	);

	return {
		...state.coinsByCharacterId,
		[oldCoin.characterId]: coins
	};
}

export default function reducer(
	state = initialState,
	action: RootAction
): LootDuck.IState {
	switch (action.type) {
		// guild payload
		case GuildDuck.FETCH_DATA_SUCCESS:
		case DemoDuck.FETCH_SUCCESS: {
			const selectionsByCharacterId: {[key: number]: LootSelection[]} = {};
			action.payload.lootSelections.forEach((selection) => {
				if (!selectionsByCharacterId[selection.characterId]) {
					selectionsByCharacterId[selection.characterId] = [];
				}

				selectionsByCharacterId[selection.characterId].push(selection);
			});

			const coinsByCharacterId: {[key: number]: LootCoin[]} = {};
			action.payload.lootCoins.forEach((coin) => {
				if (!coinsByCharacterId[coin.characterId]) {
					coinsByCharacterId[coin.characterId] = [];
				}

				coinsByCharacterId[coin.characterId].push(coin);
			});

			const optionsById: {[key: number]: LootOption} = {};
			action.payload.lootOptions.forEach((option) => {
				optionsById[option.id] = option;
			});

			return {
				...state,

				// remove data from other guilds
				selectionsByCharacterId,
				coinsByCharacterId,
				optionsById
			};
		}

		// update / create options
		case LootDuck.CREATE:
		case LootDuck.UPDATE: {
			return {...state, isSubmitting: true};
		}

		case LootDuck.CREATE_SUCCESS:
		case LootDuck.CREATE_FAILURE:
		case LootDuck.UPDATE_SUCCESS:
		case LootDuck.UPDATE_FAILURE: {
			return {...state, isSubmitting: false};
		}

		// delete options
		case LootDuck.DELETE: {
			return {...state, isDeleting: true};
		}

		case LootDuck.DELETE_SUCCESS:
		case LootDuck.DELETE_FAILURE: {
			return {...state, isDeleting: false};
		}

		// reorder options
		case LootDuck.REORDER: {
			return {
				...state,

				optionsById: {
					...state.optionsById,
					[action.payload.option.id]: action.payload.option
				}
			};
		}

		// bump
		case LootDuck.BUMP: {
			return {...state, isBumping: true};
		}

		case LootDuck.BUMP_SUCCESS:
		case LootDuck.BUMP_FAILURE: {
			return {...state, isBumping: false};
		}

		// rclc submit
		case LootDuck.RCLC_SUBMIT: {
			return {...state, isSubmittingRclc: true};
		}

		case LootDuck.RCLC_SUBMIT_SUCCESS:
		case LootDuck.RCLC_SUBMIT_FAILURE: {
			return {...state, isSubmittingRclc: false};
		}

		// make item selection
		case LootDuck.SELECTION_NOTE:
		case LootDuck.SELECTION: {
			return {
				...state,
				selectionsByCharacterId: insertOrUpdateSelection(
					state,
					action.payload.selection
				)
			};
		}

		case LootDuck.SELECTION_REMOVE: {
			return {
				...state,
				selectionsByCharacterId: deleteSelection(state, action.payload.selection)
			};
		}

		// make coin selection
		case LootDuck.COIN: {
			return {
				...state,
				coinsByCharacterId: insertOrUpdateCoin(state, action.payload.coin)
			};
		}

		case LootDuck.COIN_REMOVE: {
			return {
				...state,
				coinsByCharacterId: deleteCoin(state, action.payload.coin)
			};
		}

		// feed - options
		case feed.LOOT_OPTION_INSERT:
		case feed.LOOT_OPTION_UPDATE: {
			return {
				...state,

				optionsById: {
					...state.optionsById,
					[action.payload.newRecord.id]: new LootOption(action.payload.newRecord)
				}
			};
		}

		case feed.LOOT_OPTION_DELETE: {
			const optionsById: {[key: number]: LootOption} = {};
			Object.values(state.optionsById).forEach((option) => {
				if (option.id === action.payload.oldRecord.id) return;

				optionsById[option.id] = option;
			});

			return {
				...state,
				optionsById
			};
		}

		// feed - selections
		case feed.LOOT_SELECTION_INSERT:
		case feed.LOOT_SELECTION_UPDATE: {
			const selection = new LootSelection(action.payload.newRecord);

			return {
				...state,
				selectionsByCharacterId: insertOrUpdateSelection(state, selection)
			};
		}

		case feed.LOOT_SELECTION_DELETE: {
			return {
				...state,
				selectionsByCharacterId: deleteSelection(state, action.payload.oldRecord)
			};
		}

		// feed - coins
		case feed.LOOT_COIN_INSERT:
		case feed.LOOT_COIN_UPDATE: {
			const newCoin = new LootCoin(action.payload.newRecord);

			return {
				...state,
				coinsByCharacterId: insertOrUpdateCoin(state, newCoin)
			};
		}

		case feed.LOOT_COIN_DELETE: {
			return {
				...state,
				coinsByCharacterId: deleteCoin(state, action.payload.oldRecord)
			};
		}

		default:
			return state;
	}
}
