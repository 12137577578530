import {connect} from 'react-redux';

import {
	Difficulties,
	DEFAULT_DIFFICULTY,
	RAID_GROUPS,
	RAID_GROUP_IDS,
	RaidGroupId
} from '@constants/wow';

import * as toolbox from '../../helpers/toolbox';
import {rclcUrl} from '@helpers/urls';

import {
	DroptimizerReportDuck,
	GuildSponsorshipDuck,
	RosterBossDuck,
	CharacterDuck,
	RaidDataDuck,
	RosterDuck,
	LootDuck,
	SettingsDuck
} from '@ducks';

import type {DroptimizerItem} from '@models/droptimizerReport';
import type {IRaidItem, RaidInstance} from '../../models/raid-data';
import type {LootOption} from '../../models/loot-option';
import type {Character} from '../../models/character';
import type {Roster} from '../../models/roster';
import type {Guild} from '../../models/guild';
import type {User} from '../../models/user';

import Loot, {IMapLootProps, InstanceFilterOption} from './Loot';
import {
	addRoleAssignmentsToRosteredMap,
	filterCharactersBasedOnTags
} from '../RosterOld/RosterContainer';
import {SLOT_GROUP_TYPE_BY_SLOT} from './Container/getSlotItemGroups';
import {ItemGroup, getItemGroups} from './Container/getItemGroups';
export type {ItemGroup} from './Container/getItemGroups';

interface ICanEdit {
	canEdit: boolean;
	isOwner: boolean;
	isAdmin: boolean;
}

export interface IItemWant {
	characterName: string;
	characterClass: string;
	characterId: number;

	optionColour?: string;
	optionName: string;
	optionId: number;

	note: string | null;

	droptimizerItemData: DroptimizerItem | undefined;
}

export interface ILootViewItem extends IRaidItem {
	wantedByCharacter?: IItemWant;
	wantedBy: IItemWant[];
	wowheadBonus: string | undefined;
}

export interface ILootViewItemByWowItemId {
	[wowItemId: string]: ILootViewItem;
}
interface IOptionMap {
	[key: string]: LootOption;
}

interface ISelectOption {
	id: string | number;
	name: string;
}

interface IOwnProps {
	onBump: BoundThunk<typeof LootDuck.bumpUpdated>;
	onLootNote: BoundThunk<typeof LootDuck['updateNote']>;
	onSelect: BoundThunk<typeof LootDuck.makeSelection>;
	onCoin: BoundThunk<typeof LootDuck.makeCoin>;

	isBumping: boolean;

	isDemo: boolean;
	isAdmin: boolean;

	guild: Guild;
	user?: User;

	location: {
		query: {
			c?: string; // char
			i?: InstanceFilterOption; // instance
			d?: string; // difficulty
			r?: string; // roster
		};
	};
}

type RaidInstanceBySourceId = Map<WowBossId | WowInstanceId, RaidInstance>;

function composeRaidInstanceBySourceId(
	raidInstances: RaidInstance[]
): RaidInstanceBySourceId {
	const raidInstanceBySourceId: RaidInstanceBySourceId = new Map();

	raidInstances.forEach((raidInstance) => {
		// uncomment to show boes
		// raidInstanceBySourceId.set(raidInstance.id, raidInstance);

		raidInstance.bosses.forEach((boss) =>
			raidInstanceBySourceId.set(boss.id, raidInstance)
		);
	});

	return raidInstanceBySourceId;
}

function getRaidInstanceFromSourceIds(
	raidInstanceBySourceId: RaidInstanceBySourceId,
	sourceIds: WowBossId[]
): RaidInstance | undefined {
	for (const sourceId of sourceIds) {
		const raidInstance = raidInstanceBySourceId.get(sourceId);
		if (raidInstance) return raidInstance;
	}

	return undefined;
}

function createItemGroupsAndItemMaps({
	isShowingConduits,
	isGroupingBySlot,
	itemGroupById,
	raidInstances,
	difficulty,
	items,
	activeCharacter
}: {
	/** Either `Map<WowBossId, ItemGroup>` or `Map<SlotGroupType, ItemGroup>` */
	itemGroupById: Map<string, ItemGroup>;

	isShowingConduits: boolean;
	isGroupingBySlot: boolean;
	raidInstances: RaidInstance[];
	difficulty: Difficulties;
	items: IRaidItem[];
	activeCharacter: Character | undefined;
}) {
	const itemMap: ILootViewItemByWowItemId = {};

	const raidInstanceBySourceId = composeRaidInstanceBySourceId(raidInstances);

	items.forEach((item) => {
		if (!isShowingConduits && item.slot === 'conduit') return;

		// check if this item is from this instance
		const raidInstance =
			raidInstanceBySourceId.get(item.sourceId) ||
			(item.sourceIds
				? getRaidInstanceFromSourceIds(raidInstanceBySourceId, item.sourceIds)
				: undefined);
		if (!raidInstance) return;

		// if we're looking at a character only add in the items
		// that they're actually able to use for their class
		if (activeCharacter) {
			const allowed: {[key: string]: string[]} = item.allowed;

			// const isUsable = activeCharacter.roles.some((role) => {

			// only filter out items their class can't use at all,
			// role based filtering will be done later
			const isUsable = Object.keys(allowed).some((role) => {
				if (allowed[role].includes(activeCharacter.class)) return true;
				return false;
			});

			// don't add the item in if the character can't use it
			if (!isUsable) return;
		}

		// add the item into the relevant slot groups(s)
		if (isGroupingBySlot) {
			itemGroupById.get(SLOT_GROUP_TYPE_BY_SLOT[item.slot])?.wowItemIds.push(item.id);
		} else if (item.sourceIds?.length) {
			item.sourceIds.forEach((sourceId) => {
				itemGroupById.get(sourceId)?.wowItemIds.push(item.id);
			});
		} else {
			itemGroupById.get(item.sourceId)?.wowItemIds.push(item.id);
		}

		itemMap[item.id] = {
			...item,
			wantedBy: [],

			wowheadBonus: raidInstance.wowheadBonuses[difficulty]
		};
	});

	return {
		itemGroupById,
		itemMap
	};
}

function createOptionMapAndOptions(options: LootOption[]) {
	const optionMap: IOptionMap = {};
	const optionOptions: ISelectOption[] = [];

	options.forEach((option) => {
		optionMap[option.id] = option;

		if (!option.isDeleted) {
			optionOptions.push({
				name: option.name,
				id: option.id
			});
		}
	});

	return {
		optionOptions,
		optionMap
	};
}

interface IAddSelectionData {
	lootState: LootDuck.IState;
	droptimizerState: DroptimizerReportDuck.State;

	optionMap: IOptionMap;
	itemMap: ILootViewItemByWowItemId;
	difficulty: Difficulties;

	characters: Character[];
	activeCharacter: Character | undefined;
}

function addSelectionDataToItems(props: IAddSelectionData): void {
	props.characters.forEach((char) => {
		const selections = LootDuck.getSelectionsForCharacter(props.lootState, char.id);

		selections.forEach((selection) => {
			// only use selections from this difficulty and instance
			if (selection.difficulty !== props.difficulty) return;
			if (!props.itemMap[selection.wowItemId]) return;

			// if they have a bad option just ignore it
			const option = props.optionMap[selection.optionId];
			if (!option) return;

			const droptimizerItem = DroptimizerReportDuck.getItem(props.droptimizerState, {
				difficulty: props.difficulty,
				characterId: char.id,
				wowItemId: selection.wowItemId
			});

			const want: IItemWant = {
				characterClass: char.class,
				characterName: char.name,
				characterId: char.id,

				optionName: option.name,
				optionId: option.id,

				note: selection.note,

				droptimizerItemData: droptimizerItem
			};

			if (props.activeCharacter && props.activeCharacter.id === char.id) {
				props.itemMap[selection.wowItemId].wantedByCharacter = want;
			} else {
				props.itemMap[selection.wowItemId].wantedBy.push(want);
			}
		});
	});
}

// interface IAddCoinData {
// 	lootState: LootDuck.IState;

// 	bossMap: IBossMap;
// 	difficulty: string;

// 	activeCharacter: Character | undefined;
// }

// function addCoinDataToBosses(props: IAddCoinData) {
// 	if (!props.activeCharacter) return;

// 	const coins = LootDuck.getCoinsForCharacter(props.lootState, props.activeCharacter.id);

// 	coins.forEach((coin) => {
// 		// only use coins from this difficulty and instance
// 		if (coin.difficulty !== props.difficulty) return;
// 		if (!props.bossMap[coin.wowBossId]) return;

// 		props.bossMap[coin.wowBossId].isCharacterCoining = true;
// 	});
// }

function sortItemWants(
	itemMap: ILootViewItemByWowItemId,
	optionOptions: ISelectOption[]
) {
	const order = optionOptions.map((option) => option.id);

	Object.values(itemMap).forEach((item) => {
		item.wantedBy.sort((a, b) => {
			const ao = order.indexOf(a.optionId);
			const bo = order.indexOf(b.optionId);

			if (ao !== bo) return bo > ao ? -1 : 1;

			return toolbox.sortCharactersByClassAndName(a, b);
		});
	});
}

function filterCharactersBasedOnTagsWrapper(
	state: IRootState,
	characters: Character[],
	roster: Roster
) {
	const rosteredCharacterMap: {[charId: number]: boolean} = {};
	const roleAssignments = RosterBossDuck.getAssignmentsForRosterId(
		state.rosterBosses,
		roster.id
	);

	addRoleAssignmentsToRosteredMap(roleAssignments, rosteredCharacterMap);

	characters = filterCharactersBasedOnTags({
		tagState: state.tag,
		rosterTagIds: roster.tagWhitelist,
		isWhitelist: true,
		rosteredCharacterMap,
		characters
	});

	characters = filterCharactersBasedOnTags({
		tagState: state.tag,
		rosterTagIds: roster.tagBlacklist,
		isWhitelist: false,
		rosteredCharacterMap,
		characters
	});

	return characters;
}

function canEditCharacterLoot(
	isAdmin: boolean,
	user: User | undefined,
	queryChar: Character | undefined
): ICanEdit {
	const isOwner =
		!!queryChar && !!user && user.createComboKeys().includes(queryChar.combo);

	return {
		canEdit: isOwner || isAdmin,
		isAdmin,
		isOwner
	};
}

function getUsersCharacters(characters: Character[], user?: User) {
	if (!user || !characters.length) return [];

	const keys = user.createComboKeys();
	return characters
		.filter((char) => keys.includes(char.combo))
		.sort(toolbox.sortCharactersByClassAndName);
}

function getSelectedRaidInstances(
	raidDataState: IRootState['raidData'],
	allRaidInstances: RaidInstance[],
	queryInstanceId: InstanceFilterOption | undefined,
	isDemo: boolean
): {
	selectedRaidInstances: RaidInstance[];
	queryInstanceSelection: InstanceFilterOption;
} {
	const defaultRaidInstance = allRaidInstances[0];

	// if there's no id, but there are raid groups, default to the first one
	const hasRaidGroups = !!RAID_GROUP_IDS.length;
	if (!queryInstanceId && hasRaidGroups && !isDemo) {
		queryInstanceId = RAID_GROUP_IDS[0];
	}

	if (!queryInstanceId) {
		return {
			selectedRaidInstances: [defaultRaidInstance],
			queryInstanceSelection: defaultRaidInstance.id
		};
	}

	if (RAID_GROUP_IDS.includes(queryInstanceId as RaidGroupId)) {
		return {
			selectedRaidInstances: RaidDataDuck.getRaidInstancesForRaidGroupName(
				raidDataState,
				queryInstanceId as RaidGroupId
			),
			queryInstanceSelection: queryInstanceId
		};
	}

	const raidInstance =
		RaidDataDuck.getRaidInstance(raidDataState, queryInstanceId) || defaultRaidInstance;

	return {
		selectedRaidInstances: [raidInstance],
		queryInstanceSelection: raidInstance.id
	};
}

function composeInstanceSelectionOptions(
	allRaidInstances: RaidInstance[],
	isDemo: boolean
): ISelectOption[] {
	const selectionOptions: ISelectOption[] = allRaidInstances.map((instance) => ({
		name: instance.name,
		id: instance.id
	}));

	if (!isDemo) {
		RAID_GROUPS.forEach((raidGroup) => {
			selectionOptions.unshift({
				name: raidGroup.label,
				id: raidGroup.id
			});
		});
	}

	return selectionOptions;
}

function mapStateToProps(state: IRootState, props: IOwnProps): IMapLootProps {
	const fc = GuildSponsorshipDuck.createFcForGuildId(
		state.guildSponsorships,
		state.guilds,
		props.guild.id
	);
	const canUseTagging = fc.canUseTagging();

	// get the difficulty from the url, or use the fallback
	const rawDifficulty = props.location.query.d as Difficulties | undefined;
	const difficulty: Difficulties =
		rawDifficulty && Object.values(Difficulties).includes(rawDifficulty)
			? rawDifficulty
			: DEFAULT_DIFFICULTY;

	// get the instance from the url, or use the fallback
	const allRaidInstances = RaidDataDuck.getAllRaidInstances(state.raidData);
	const {queryInstanceSelection, selectedRaidInstances} = getSelectedRaidInstances(
		state.raidData,
		allRaidInstances,
		props.location.query.i,
		props.isDemo
	);

	let characters = CharacterDuck.getCharactersForGuild(state.characters, props.guild.id);

	const rosters = RosterDuck.getRostersForGuild(state.rosters, props.guild.id);
	let selectedRosterId: number | undefined;
	const queryRosterId = props.location.query.r;
	if (queryRosterId && canUseTagging) {
		const roster = rosters.find((x) => x.id === Number.parseInt(queryRosterId, 10));

		if (roster) {
			selectedRosterId = roster.id;

			characters = filterCharactersBasedOnTagsWrapper(state, characters, roster);
		}
	}

	const isShowingConduits = SettingsDuck.getLootShowConduits(state.settings);
	const isGroupingBySlot = SettingsDuck.getLootGroupBySlot(state.settings);

	// start building up the props
	const data: IMapLootProps = {
		canUseTagging,

		isBumping: props.isBumping,
		isDroptimizerRelativeGain: SettingsDuck.getIsDroptimizerRelativeGain(
			state.settings
		),
		isShowingConduits,
		isGroupingBySlot,

		isShowingRclcButton: props.isAdmin && !props.isDemo,
		isAllowedToEdit: false,
		isCharacterOwner: false,
		isCharacterAdmin: false,

		ownCharacters: getUsersCharacters(characters, props.user),
		rosters,

		rosterId: selectedRosterId,
		wowInstanceId: queryInstanceSelection,
		difficulty,
		queryId: '',

		rclcUrl: rclcUrl(props.guild.id),
		itemMap: {},
		itemGroups: [],

		optionOptions: [],

		instanceOptions: composeInstanceSelectionOptions(allRaidInstances, props.isDemo),

		filterOptions: characters.sort(toolbox.sortCharactersByName).map((char) => ({
			label: char.name,
			value: char.id
		})),

		characterDroptimizerItemByWowItemId: undefined
	};

	// try find the character being filtered to if there is one
	let activeCharacter: Character | undefined;
	if (props.location.query.c) {
		const c = props.location.query.c;
		data.queryId = c;

		const cid = Number(c);
		for (const char of characters) {
			if (char.id === cid) {
				activeCharacter = char;
				break;
			}
		}

		// if no character was actually found then we can exit here
		// and just display the "no character found" error
		if (!activeCharacter) return data;
	}

	const itemGroupsWithoutItems = getItemGroups({
		rosterBossesState: state.rosterBosses,

		isGroupingBySlot,
		raidInstances: selectedRaidInstances,
		selectedRosterId,
		rosters
	});

	const items = RaidDataDuck.getAllRaidItems(state.raidData);
	const {itemMap, itemGroupById} = createItemGroupsAndItemMaps({
		itemGroupById: itemGroupsWithoutItems,
		raidInstances: selectedRaidInstances,
		activeCharacter,
		difficulty,
		items,
		isGroupingBySlot,
		isShowingConduits
	});

	const lootOptions = LootDuck.getOptionsForGuild(state.loot, props.guild.id);
	const {optionMap, optionOptions} = createOptionMapAndOptions(lootOptions);

	addSelectionDataToItems({
		droptimizerState: state.droptimizerReports,
		lootState: state.loot,

		difficulty,
		optionMap,
		itemMap,

		characters,
		activeCharacter
	});

	// addCoinDataToBosses({
	// 	lootState: state.loot,

	// 	difficulty,
	// 	bossMap,

	// 	activeCharacter
	// });

	sortItemWants(itemMap, optionOptions);

	const edit = canEditCharacterLoot(props.isAdmin, props.user, activeCharacter);

	return {
		...data,

		isAllowedToEdit: edit.canEdit,
		isCharacterOwner: !!edit.isOwner,
		isCharacterAdmin: !!edit.isAdmin,

		characterDroptimizerItemByWowItemId: activeCharacter
			? DroptimizerReportDuck.getItemByWowItemIdForCharacter(
					state.droptimizerReports,
					{
						characterId: activeCharacter.id,
						difficulty
					}
			  )
			: undefined,
		characterId: activeCharacter?.id,
		activeCharacter,

		optionOptions,
		itemMap,
		itemGroups: [...itemGroupById.values()]
	};
}

export default connect(mapStateToProps, {
	onToggleDroptimizerRelativeGain: SettingsDuck.setDroptimizerRelativeGain,
	onToggleLootShowConduits: SettingsDuck.setLootShowConduits,
	onToggleLootGroupBySlot: SettingsDuck.setLootGroupBySlot
})(Loot);
